@import "../../sass/breakpoints.scss";
.hero{

 
    overflow: hidden;
   .video{
   
    position: absolute;

    width:100%;
    height:0px;
    padding-bottom:56.25%;


   
}

video{

    width: 100%;
    height:auto;
    transition: transform 500ms;
    
}
    
 
}


@keyframes scale_image {
    0%   {transform: scale(1.2); opacity: 0;}
    100% {transform: scale(1);opacity: 1;}

}
.overlay{
    position: absolute;
   
  
    width:100%;
    height:100%;
    display: none;




    @include breakpoint(sm) {
        display: block;
        

    }
    


   
}
.animation_test{

    animation: scale_image 2s;
}
.copy{

position: absolute;
top:0;
left:0;
width:100%;
height:100%;

color:#fff;
display: flex;
justify-content: center;
align-items: center;


.project_title{
  
    font-family: "SangBlueKingdom-MediumS";
    
    display: none;
 
    font-size: 2.4rem;
    line-height: 2.7rem;
   



    

    @include breakpoint(sm) {
        display: block;
        text-align: center;

        max-width:600px;
        font-size: 5rem;
        line-height: 5.8rem;
    
        
        span{
            background-image: linear-gradient(to right, rgba(255,255,255,0.3),rgba(255,255,255,0.3) 100%);
            background-position: 0 5.8rem;
            background-repeat: no-repeat;
            background-size: 100% 2px;
        
        }
    }

    @include breakpoint(lg) {
     
        font-size: 6rem;
        line-height: 6.8rem;
      
        span{
          
            background-position: 0 7.1rem;
            background-repeat: no-repeat;
            background-size: 100% 3px;
        
        }
    }

}

}