@import "../../sass/breakpoints.scss";
.thumbnail{
    position: relative;
   
    .image{
   
        overflow: hidden;


    }

    .video{
   
        overflow: hidden;
        width:100%;
        height:0px;
        padding-bottom:56.25%;
    
   
       
    }

    video{
        width: 100%;
        height:auto;
        transition: transform 500ms;
        
    }
    

    img{
        width: 100%;
        height:auto;
        transition: transform 500ms;


        
    }
    

    .copy{
        color:black;

        width:100%;
      

        margin:1.2rem 0 3rem 0;
        padding-left:$margin-default;
        display: flex;
        flex-direction: column;
        @include breakpoint(sm) {
            margin:.8rem 0 4rem 0rem;
            padding-left:0;
            flex-direction: row;
        }

        @include breakpoint(md) {
          
            margin:1.5rem 0 2rem 2rem;
            flex-direction: column;
        }

        

        .project_name{
            font-family: "SangBlueKingdom-MediumS";
       
            font-size:2.8rem;
            width:30rem;
            line-height: 1.2;
            margin:.3rem 0 1rem 0;
      
         
            .underline{
                background-image: linear-gradient(to right, rgba(0,0,0,0.4) 0, rgba(0,0,0,0.4) 100%);
                background-position: 0 3.3rem;
                background-repeat: no-repeat;
                background-size: 100% 2px;
                
               
            }
            @include breakpoint(sm) {
                
                font-size:3.6rem;
                line-height: 4rem;
                padding-left:0;
                margin:.5rem 4rem 1rem 0;
                width:40rem;
                .underline{
                    background-position: 0 4.3rem;
                    background-size: 100% 2px;
                    transition: background-size 900ms;
                }
            }
            @include breakpoint(md) {
                font-size:3.4rem;
                line-height: 3.6rem;
                width:30rem;
                margin:.5rem 0 .5rem 0;
                color:white;
                .underline{
                    background-position: 0 4.1rem;
                    background-size: 0% 2px;
                    background-image: linear-gradient(to right,rgba(255,255,255,0.3) 0,rgba(255,255,255,0.3)100%);
                }
            }
            @include breakpoint(lg) {
                margin:1rem 0 1rem 0;
                font-size:4rem;
              
                line-height: 4.2rem;
                .underline{
                    background-position: 0 4.8rem;
                    background-size: 0% 2px;
                    background-image: linear-gradient(to right,white 0, white 100%);
                    background-image: linear-gradient(to right,rgba(255,255,255,0.3) 0,rgba(255,255,255,0.3)100%);
                }
               
   
            }

        }

        .project_tagline{
            font-size:1.6rem;
            width: 90%;
            color:$light-gray;
            line-height: 1.2;
            font-family: 'EuclidSquare-Medium';
            @include breakpoint(sm) {
                margin:1rem 0 1rem 0;
                font-size:2rem;
                line-height: 1.2;
                flex-basis: 30%;
            }
       
            @include breakpoint(md) {
                font-size:1.6rem;
                width:25rem;
                line-height: 1.2;
                display: block;
                color:white;
             
              
            }

            @include breakpoint(lg) {
                font-size:1.6rem;
                width:30rem;
               
            
            }
        }
    
    }
   
        
      
    
.project_info_mobile{
   
    @include breakpoint(md) {
        display: none;
    }
}

.project_info{
    display: none;
    

    @include breakpoint(md) {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: .5s;
        display: flex;
    }
    
    .overlay{
  
        


        @include breakpoint(md) {
            
           
            width:100%;
         
            transition: margin .5s;
            background:rgba(0, 0, 0,0.6);
            display: flex;
        }

        @include breakpoint(lg) {
            
        }



        
    }

   
}    






}