@import "../../sass/breakpoints.scss";

.parallax{
    border:"10px solid red";
}
.imageContainer{

    display:flex;
   
    margin:1.5rem;
    background-size: 100%;

    @include breakpoint(sm) {
     
        margin:2.5rem;
      }

}

.image{
    overflow: hidden;
    border-radius: 0.8rem;
    margin:2rem 0 2rem 0;
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, .15);
    img {
        width: 100%; 
        height: auto;

        
    }
    &:first-child{
        margin-left:2rem;
        margin-right:2rem;
        @include breakpoint(sm) {
            margin-left:8rem;
            margin-right:4rem;
          
        }

        @include breakpoint(sm) {
            margin-left:8rem;
            margin-right:4rem;
          
        }

        @include breakpoint(md) {
            margin-right:8rem;
            margin-left:6rem;
           
        }
  
    }

    &:last-child{
        margin-right:2rem;
        margin-left:2rem;
        @include breakpoint(sm) {
            margin-right:8rem;
            margin-left:4rem;
           
        }

        @include breakpoint(md) {
            margin-right:8rem;
            margin-left:6rem;
           
        }
    }

    @include breakpoint(sm) {
     
        border-radius:1.2rem;
        margin:5rem 0 5rem 0;
      }

      @include breakpoint(md) {
        
        border-radius:1.6rem;
        margin:10rem 0 10rem 0;
        box-shadow: 0 20px 50px 0px rgba(0, 0, 0, .2);
      }
      @include breakpoint(lg) {
        margin:15rem 0 15rem 0;
     
      }
}