@font-face {
    font-family: 'NeutrifStudio-Regular';
    src: url("../fonts/neutrif/neutrif-studio-regular/webfonts/neutrif-studio-regular.eot");
    src: url("../fonts/neutrif/neutrif-studio-regular/webfonts/neutrif-studio-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/neutrif/neutrif-studio-regular/webfonts/neutrif-studio-regular.woff2") format("woff2"), url("../fonts/neutrif/neutrif-studio-regular/webfonts/neutrif-studio-regular.woff") format("woff"), url("../fonts/neutrif/neutrif-studio-regular/webfonts/neutrif-studio-regular.ttf") format("truetype"), url("../fonts/neutrif/neutrif-studio-regular/webfonts/neutrif-studio-regular.svg#youworkforthem") format("svg");
    font-weight: normal;
    font-style: normal; }
  @font-face {
    font-family: 'NeutrifStudio-SemiBold';
    src: url("../fonts/neutrif/neutrif-studio-semi-bold/webfonts/neutrif-studio-semi-bold.eot");
    src: url("../fonts/neutrif/neutrif-studio-semi-bold/webfonts/neutrif-studio-semi-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/neutrif/neutrif-studio-semi-bold/webfonts/neutrif-studio-semi-bold.woff2") format("woff2"), url("../fonts/neutrif/neutrif-studio-semi-bold/webfonts/neutrif-studio-semi-bold.woff") format("woff"), url("../fonts/neutrif/neutrif-studio-semi-bold/webfonts/neutrif-studio-semi-bold.ttf") format("truetype"), url("../fonts/neutrif/neutrif-studio-semi-bold/webfonts/neutrif-studio-semi-bold.svg#youworkforthem") format("svg");
    font-weight: normal;
    font-style: normal; }
  @font-face {
    font-family: 'NeutrifStudio-Bold';
    src: url("../fonts/neutrif/neutrif-studio-bold/webfonts/neutrif-studio-bold.eot");
    src: url("../fonts/neutrif/neutrif-studio-bold/webfonts/neutrif-studio-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/neutrif/neutrif-studio-bold/webfonts/neutrif-studio-bold.woff2") format("woff2"), url("../fonts/neutrif/neutrif-studio-bold/webfonts/neutrif-studio-bold.woff") format("woff"), url("../fonts/neutrif/neutrif-studio-bold/webfonts/neutrif-studio-bold.ttf") format("truetype"), url("../fonts/neutrif/neutrif-studio-bold/webfonts/neutrif-studio-bold.svg#youworkforthem") format("svg");
    font-weight: normal;
    font-style: normal; }

    @font-face {
      font-family: 'SangBlueEmpire-Medium';
      src: url("../fonts/sangblue/EOT/SangBleuEmpire-Medium-WebTrial.eot");
      src: url("../fonts/sangblue/EOT/SangBleuEmpire-Medium-WebTrial.eot?#iefix") format("embedded-opentype"), url("../fonts/sangblue/WOFF2/SangBleuEmpire-Medium-WebTrial.woff2") format("woff2"), url("../fonts/neutrif/neutrif-studio-bold/webfonts/neutrif-studio-bold.woff") format("woff"), url("../fonts/neutrif/neutrif-studio-bold/webfonts/neutrif-studio-bold.ttf") format("truetype"), url("../fonts/neutrif/neutrif-studio-bold/webfonts/neutrif-studio-bold.svg#youworkforthem") format("svg");
      font-weight: normal;
      font-style: normal; }
  @font-face {
    font-family: 'SangBlueEmpire-Bold';
    src: url("../fonts/sangblue/EOT/SangBleuEmpire-Bold-WebTrial.eot");
    src: url("../fonts/sangblue/EOT/SangBleuEmpire-Bold-WebTrial.eot?#iefix") format("embedded-opentype"), url("../fonts/sangblue/WOFF2/SangBleuEmpire-Bold-WebTrial.woff2") format("woff2"), url("../fonts/neutrif/neutrif-studio-bold/webfonts/neutrif-studio-bold.woff") format("woff"), url("../fonts/neutrif/neutrif-studio-bold/webfonts/neutrif-studio-bold.ttf") format("truetype"), url("../fonts/neutrif/neutrif-studio-bold/webfonts/neutrif-studio-bold.svg#youworkforthem") format("svg");
    font-weight: normal;
    font-style: normal; }

    @font-face {
      font-family: 'SangBlueEmpire-Black';
      src: url("../fonts/sangblue/EOT/SangBleuEmpire-Medium-WebTrial.eot");
      src: url("../fonts/sangblue/EOT/SangBleuEmpire-Medium-WebTrial.eot?#iefix") format("embedded-opentype"), url("../fonts/sangblue/WOFF2/SangBleuEmpire-Medium-WebTrial.woff2") format("woff2"), url("../fonts/neutrif/neutrif-studio-bold/webfonts/neutrif-studio-bold.woff") format("woff"), url("../fonts/neutrif/neutrif-studio-bold/webfonts/neutrif-studio-bold.ttf") format("truetype"), url("../fonts/neutrif/neutrif-studio-bold/webfonts/neutrif-studio-bold.svg#youworkforthem") format("svg");
      font-weight: normal;
      font-style: normal; }
  
    @font-face {
      font-family: 'EuclidSquare-Bold';
    
      src: url("../fonts/_euclid_square/EuclidSquare-Bold-WebTrial.woff") format("woff");
      font-weight: normal;
      font-style: normal; }
  
      @font-face {
        font-family: 'EuclidSquare-Semibold';
      
        src: url("../fonts/_euclid_square/EuclidSquare-Semibold-WebTrial.woff") format("woff");
        font-weight: normal;
        font-style: normal; }
  
        @font-face {
          font-family: 'EuclidSquare-Medium';
        
          src: url("../fonts/_euclid_square/EuclidSquare-Medium-WebTrial.woff") format("woff");
          font-weight: normal;
          font-style: normal; }
  
        @font-face {
          font-family: 'EuclidSquare-Regular';
        
          src: url("../fonts/_euclid_square/EuclidSquare-Regular-WebTrial.woff") format("woff");
          font-weight: normal;
          font-style: normal; }
  
          @font-face {
            font-family: 'EuclidSquare-Light';
          
            src: url("../fonts/_euclid_square/EuclidSquare-Light-WebTrial.woff") format("woff");
            font-weight: normal;
            font-style: normal; }

            @font-face {
              font-family: 'SangBlueKingdom-Medium';
              src: url("../fonts/sangblue/EOT/SangBleuKingdom-Medium-WebTrial.eot");
              src: url("../fonts/sangblue/EOT/SangBleuKingdom-Medium-WebTrial.eot?#iefix") format("embedded-opentype"), 
              url("../fonts/sangblue/WOFF2/SangBleuKingdom-Medium-WebTrial.woff2") format("woff2"), 
              url("../fonts/sangblue/WOFF/SangBleuKingdom-Medium-WebTrial.woff") format("woff"), 
              url("../fonts/sangblue/TTF/SangBleuKingdom-Medium-WebTrial.ttf") format("ttf"), 
              url("../fonts/sangblue/SVG/SangBleuKingdom-Medium-WebTrial.svg") format("svg");
              font-weight: normal;
              font-style: normal; }

              @font-face {
                font-family: 'SangBlueKingdom-Regular';
                src: url("../fonts/sangblue/EOT/SangBleuKingdom-Regular-WebTrial.eot");
                src: url("../fonts/sangblue/EOT/SangBleuKingdom-Regular-WebTrial.eot?#iefix") format("embedded-opentype"), 
                url("../fonts/sangblue/WOFF2/SangBleuKingdom-Regular-WebTrial.woff2") format("woff2"), 
                url("../fonts/sangblue/WOFF/SangBleuKingdom-Regular-WebTrial.woff") format("woff"), 
                url("../fonts/sangblue/TTF/SangBleuKingdom-Regular-WebTrial.ttf") format("ttf"), 
                url("../fonts/sangblue/SVG/SangBleuKingdom-Regular-WebTrial.svg") format("svg");
                font-weight: normal;
                font-style: normal; }
                @font-face {
                  font-family: 'SangBlueKingdom-MediumS';
                  src: url("../fonts/licensed/SangBleuKingdom-Medium-Web/S/SangBleuKingdom-Medium-WebS.eot");
                  src: url("../fonts/licensed/SangBleuKingdom-Medium-Web/S/SangBleuKingdom-Medium-WebS.eot?#iefix") format("embedded-opentype"), 
                  url("../fonts/licensed/SangBleuKingdom-Medium-Web/S/SangBleuKingdom-Medium-WebS.woff2") format("woff2"), 
                  url("../fonts/licensed/SangBleuKingdom-Medium-Web/S/SangBleuKingdom-Medium-WebS.woff") format("woff"), 
                  url("../fonts/licensed/SangBleuKingdom-Medium-Web/S/SangBleuKingdom-Medium-WebS.ttf") format("ttf"), 
                  url("../fonts/licensed/SangBleuKingdom-Medium-Web/S/SangBleuKingdom-Medium-WebS.svg") format("svg");
                  font-weight: normal;
                  font-style: normal; }

                @font-face {
                  font-family: 'SangBlueKingdom-MediumM';
                  src: url("../fonts/licensed/SangBleuKingdom-Medium-Web/M/SangBleuKingdom-Medium-WebM.eot");
                  src: url("../fonts/licensed/SangBleuKingdom-Medium-Web/M/SangBleuKingdom-Medium-WebM.eot?#iefix") format("embedded-opentype"), 
                  url("../fonts/licensed/SangBleuKingdom-Medium-Web/M/SangBleuKingdom-Medium-WebM.woff2") format("woff2"), 
                  url("../fonts/licensed/SangBleuKingdom-Medium-Web/M/SangBleuKingdom-Medium-WebM.woff") format("woff"), 
                  url("../fonts/licensed/SangBleuKingdom-Medium-Web/M/SangBleuKingdom-Medium-WebM.ttf") format("ttf"), 
                  url("../fonts/licensed/SangBleuKingdom-Medium-Web/M/SangBleuKingdom-Medium-WebM.svg") format("svg");
                  font-weight: normal;
                  font-style: normal; }

                    @font-face {
                  font-family: 'SangBlueKingdom-MediumXL';
                  src: url("../fonts/licensed/SangBleuKingdom-Medium-Web/XL/SangBleuKingdom-Medium-WebXL.eot");
                  src: url("../fonts/licensed/SangBleuKingdom-Medium-Web/XL/SangBleuKingdom-Medium-WebXL.eot?#iefix") format("embedded-opentype"), 
                  url("../fonts/licensed/SangBleuKingdom-Medium-Web/XL/SangBleuKingdom-Medium-WebXL.woff2") format("woff2"), 
                  url("../fonts/licensed/SangBleuKingdom-Medium-Web/XL/SangBleuKingdom-Medium-WebXL.woff") format("woff"), 
                  url("../fonts/licensed/SangBleuKingdom-Medium-Web/XL/SangBleuKingdom-Medium-WebXL.ttf") format("ttf"), 
                  url("../fonts/licensed/SangBleuKingdom-Medium-Web/XL/SangBleuKingdom-Medium-WebXL.svg") format("svg");
                  font-weight: normal;
                  font-style: normal; }

                  @font-face {
                    font-family: 'EuclidSquare-MediumS';
                    src: url("../fonts/licensed/EuclidSquare-Medium-Web/S/EuclidSquare-Medium-WebS.eot");
                    src: url("../fonts/licensed/EuclidSquare-Medium-Web/S/EuclidSquare-Medium-WebS.eot?#iefix") format("embedded-opentype"), 
                    url("../fonts/licensed/EuclidSquare-Medium-Web/S/EuclidSquare-Medium-WebS.woff2") format("woff2"), 
                    url("../fonts/licensed/EuclidSquare-Medium-Web/S/EuclidSquare-Medium-WebS.woff") format("woff"), 
                    url("../fonts/licensed/EuclidSquare-Medium-Web/S/EuclidSquare-Medium-WebS.ttf") format("ttf"), 
                    url("../fonts/licensed/EuclidSquare-Medium-Web/S/EuclidSquare-Medium-WebS.svg") format("svg");
                    font-weight: normal;
                    font-style: normal; }

                    @font-face {
                      font-family: 'EuclidSquare-SemiboldS';
                      src: url("../fonts/licensed/EuclidSquare-Semibold-Web/S/EuclidSquare-Semibold-WebS.eot");
                      src: url("../fonts/licensed/EuclidSquare-Semibold-Web/S/EuclidSquare-Semibold-WebS.eot?#iefix") format("embedded-opentype"), 
                      url("../fonts/licensed/EuclidSquare-Semibold-Web/S/EuclidSquare-Semibold-WebS.woff2") format("woff2"), 
                      url("../fonts/licensed/EuclidSquare-Semibold-Web/S/EuclidSquare-Semibold-WebS.woff") format("woff"), 
                      url("../fonts/licensed/EuclidSquare-Semibold-Web/S/EuclidSquare-Semibold-WebS.ttf") format("ttf"), 
                      url("../fonts/licensed/EuclidSquare-Semibold-Web/S/EuclidSquare-Semibold-WebS.svg") format("svg");
                      font-weight: normal;
                      font-style: normal; }


                  
                    
          
  
  *{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
            html {
    font-size: 62.5%; }
  
  body {
    font-family: 'NeutrifStudio-Regular';
    
    margin: 0;
    padding: 0; }


