
@import "../../sass/breakpoints.scss";
.intro{
   
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:2rem 3rem 1rem 2rem;
     
    
    @include breakpoint(sm) {

        margin:6rem 0 6rem 0;
    
      }

      @include breakpoint(md) {
       
        margin:10rem 0rem 8rem 0;
      }

    .project_title{
      font-family: "SangBlueKingdom-MediumS";
      font-size:2.8rem;
      line-height: 1.2;
      margin:0 0 2rem 0;
      @include breakpoint(sm) {
        display:none;
      }

    }

    .underline{
      background-image: linear-gradient(to right, black 0, black 100%);
      background-position: 0 3.3rem;
      background-repeat: no-repeat;
      background-size: 100% 2px;
    }
   
    .description{
   
  
        font-size: 1.8rem;
        
        max-width: 700px;
        padding: 0;
    
    
        font-family: 'EuclidSquare-MediumS';
        
        @include breakpoint(sm) {
 
            font-size: 2rem;
            line-height:3.4rem;
        }

        @include breakpoint(md) {
     
        }
        
    }

    .created_at{

      font-family: 'EuclidSquare-MediumS';
    
  
      
      font-size: 1.4rem;
     
      max-width: 700px;
      padding: 0;
      color: $light-gray;
      margin:2rem 0 2rem 0;
  
      @include breakpoint(sm) {
            
              font-size: 1.4rem;
              line-height:3.4rem;
          }
  
          @include breakpoint(md) {
            
          }
  }
}