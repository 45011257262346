@import "../../sass/breakpoints.scss";








.menu_hamburger{
  display: block;
  color:white;
  cursor: pointer;
  
  width:2.6rem;

  margin: 1.3rem 1.5rem 0.7rem 1rem;


 
  @include breakpoint(sm) {
    display: none;
  
  }

  .hamburger_line{
    background-color: black;
    height:2px;
    margin-bottom: 6px;
    transition:all 500ms;
 
  }

}






.menu{
 

   
    
    padding: 1.2rem 0 1.2rem 0;
 
    font-family: 'EuclidSquare-Medium';
    background-color: transparent;
 

   
    position: fixed;
    top: 0;
    width: 100%;

    transition:margin-top 500ms, background-color 100ms;
    
    z-index: 1000;

    @include breakpoint(sm) {
      padding: 2.5rem 0 2.5rem 0;
     
    }

    .selected{
      font-size:2rem;
      line-height: 3.2rem;
      background-image: linear-gradient(to right, black 0, black 100%);
      background-size: 100% 3px;
      background-repeat: no-repeat;
      background-position: 0 2.8rem;
      display: inline-block;
      transition: all 500ms;
    }
    .section{
      background-position: 0 2.8rem;
      font-size:2rem;
      line-height: 3.2rem;
      background-image: linear-gradient(to right, black 0, black 100%);
      background-size: 0% 3px;
      background-repeat: no-repeat;
      display: inline-block;
      transition: all 500ms;

      &:hover{

        background-size: 100% 3px;
        transition: all 500ms;
      }
    }


    

    .logo{
    
     
      margin-right: auto;
      margin-left:$margin-default;
      margin-top:0.9rem;
      z-index: 10;
      height:18px;
      width:36px;
      @include breakpoint(sm) {
        margin-top:0.6rem;
        margin-left:$margin-small;
     
  
      }

      @include breakpoint(md) {
        margin-left:$margin-medium;
      
  
      }
      svg{
        transform: scale(0.8);
        transform-origin: 0 0;
        @include breakpoint(sm) {
          transform: scale(1)
        }
      }

      
    }


    .desktop_menus{
  
      display: flex;
   
     
    }

    .hamburger_buttons{
     
      display: flex;
      justify-content: flex-end;
    

    }
    


    .menu_hamburger_block{
     
  
      
      display: flex;
  
      position: absolute;
      
      justify-content:center;
      align-items: center;
      flex-direction:column;
      height:100vh;
      width:100%;
      top:0;
      transition: opacity 500ms;
    

      @include breakpoint(sm) {
       

      }

      @include breakpoint(md) {
        
      }

      
      @keyframes item_large {
        from {transform:translateY(200px); opacity:0}
        to {transform:translateY(0);opacity:1}
      }

      .first_item{
        animation-duration: 800ms;
      }
  
      .second_item{
        animation-duration: 1200ms;
      }

      .menu_item_large{
      
        background-position: 0 4.4rem;
        font-size:3.5rem;
        line-height: 4.8rem;
        background-image: linear-gradient(to right, white 0, white 100%);
        background-size: 0% 3px;
        background-repeat: no-repeat;
        
        transition: all 500ms;
        color:white;
        padding-bottom: 1rem;
        display: inline-block;
        animation-name: item_large;
   
        &:hover{
      
          background-size: 100% 3px;
          transition: all 500ms;
        }
      }
      
      
      .menu_item_large_selected{
     
        font-size:3.5rem;
        line-height: 4.8rem;
        color:white;
        padding-bottom: 1rem;
        background-image: linear-gradient(to right, white 0, white 100%);
        background-size: 100% 3px;
        background-repeat: no-repeat;
        background-position: 0 4.3rem;
        display: inline-block;
        transition: all 500ms;
        animation-name: item_large;
    
      }

      a {
        color: black;
        
      
        transition: color 500ms; 
        
        &:hover{
          text-decoration: none;
          
        }

         
        }

    }

   
    .menu_block{
        
      display: none;
      margin-right:2.5rem;

      z-index: 10;
      @include breakpoint(sm) {
        display: block;
      }
   
      a {
        color: black;
        
      
        transition: color 500ms; 
        
        &:hover{
          text-decoration: none;
          
        }

         &:last-child{
          
          padding-left: 2rem;
         }
        }
      }
      
}

