@import "../../sass/breakpoints.scss";
.image_container{
    
    display:flex;
    flex-direction: column;
    margin:1.5rem;
    @include breakpoint(sm) {
        flex-direction:row;
        margin:2.5rem;
      }
    
}



.image{
  
    
   img {
        width: 100%; 
        height: auto;
    }
      
      
    &:first-child{
        
    }

    &:last-child{
 
    }
}