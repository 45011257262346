@import "../../sass/breakpoints.scss";

$border-width: 3px;


.image_container{
    
 
    display:flex;
    flex-direction: column;
    overflow: hidden;

  

    @include breakpoint(sm) {

    }

    @include breakpoint(md) {
      
    }
    @include breakpoint(lg) {
        flex-direction: row;
    }
    
    
}


.margins_none{
    margin:0 1.5rem 0 1.5rem;

    @include breakpoint(sm) {
    
        margin:0 2.5rem 0 2.5rem;
      }
}

.margins_both{
    margin:1.5rem 1.5rem 1.5rem 1.5rem;

    @include breakpoint(sm) {
    
        margin:4rem 2.5rem 4rem 2.5rem;
      }
}

.margins_top{

    margin:1.5rem 1.5rem 0 1.5rem;

    @include breakpoint(sm) {
    
        margin:4rem 2.5rem 0 2.5rem;
      }
    
}


.margins_bottom{
    margin:0 1.5rem 1.5rem 1.5rem;

    @include breakpoint(sm) {
    
        margin:0 2.5rem 4rem 2.5rem;
      }
}



.column_large{



 
    display:flex;
  
    flex-basis: 100%;



    @include breakpoint(md) {
        flex-basis: 100%;
        
      }
    @include breakpoint(lg) {
        flex-basis: 66.666%;
       
        
      }
    

}

.border_right{

    @include breakpoint(lg) {
  
        border-right:$border-width solid white;
        
      }

}

.column_small{
    
   


    flex-basis: 100%;
    display:flex;
    align-content: flex-start;
    flex-wrap: wrap;
    @include breakpoint(md) {
   
      }
    @include breakpoint(lg) {
        flex-basis: 33.333%;
        
      }

      
      
}




.image_half{
    flex-basis: 100%;
    position: relative;

    
    @include breakpoint(sm) {
        flex-basis: 50%;
        &:nth-child(2)  { 
            border-left:$border-width solid white;
        }  
    }
    @include breakpoint(md) {
        flex-basis: 50%;
    }

    @include breakpoint(lg) {
        flex-basis: 100%;
        &:nth-child(2)  { 
            border-left:0 solid white;
        }     
    }  
}

.image_half_noborder{
    flex-basis: 100%;
    position: relative;

    
    @include breakpoint(sm) {
        flex-basis: 50%;
         
    }
    @include breakpoint(md) {
        flex-basis: 50%;
    }

    @include breakpoint(lg) {
        flex-basis: 100%;
           
    }  
}

.outside_image{

    position: relative;

    height: 0;
    padding-bottom: 56.25%;
   


}

.border{
    background-color:white;
   
}

.inside_image{
 
    width:100%;
    height:100%;
    position: relative;
 

}
.image{



    
    flex-basis: 100%;


  
}