@import "../../sass/breakpoints.scss";





.imageContainer{

    display:flex;
    flex-direction:row;
 

   
    align-items: flex-start;
  
    position: relative;
   

    


    


      

         
   

}

.margin{
    margin-top:-15rem;
@include breakpoint(sm) {
    margin-top:-20rem;
}

@include breakpoint(md) {
    margin-top:-30rem;
}
}

.no_margin{
    margin-top:0;
}

.column{

   
    margin:3rem 0 3rem 0;
    @include breakpoint(sm) {
        margin:4rem 0 4rem 0;
    }
    @include breakpoint(md) {
        margin:8rem 0 8rem 0;
    }


&:first-child{

    margin-right:1rem;
        @include breakpoint(sm) {
            margin-right:2rem;
        }

        @include breakpoint(md) {
            margin-right:3rem;
        }
   


 

}

&:last-child{
    margin-left:1rem;
    @include breakpoint(sm) {
        margin-left:2rem;
    }

    @include breakpoint(md) {
        margin-left:3rem;
    }
   


 

}

}

.image{
    
    border-radius: 0rem;

    margin:1rem 0 1rem 0;
    box-shadow: 0 5px 30px 0px rgba(0, 0, 0, .15);
 
    img {
        width: 100%; 
    

        
    }
    @include breakpoint(sm) {
        margin:2rem 0 2rem 0;
    }
    @include breakpoint(md) {
        margin:3rem 0 3rem 0;
    }
   

    
    
}