@import "../../sass/breakpoints.scss";
.description{

   
    font-family: 'EuclidSquare-MediumS';
    display: flex;
    margin:2rem 2.5rem 4rem 2rem;
    font-size: 1.6rem;

    @include breakpoint(sm) {
        margin:3rem 2.5rem 6rem 2.5rem;
    }

    @include breakpoint(md) {

    }
    
    .copy{
        flex-basis: 90%;

        @include breakpoint(sm) {
       
        }   

        @include breakpoint(md) {
            flex-basis: 50%;

        }
    }

    a{
        color:black;
        text-decoration: none;
        &:hover{
            text-decoration: none;
            color:pink;
        }
    }
}