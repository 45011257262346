
@import "../../sass/breakpoints.scss";
.about_content{

   
    padding-top:2rem;
    display: flex;
    flex-flow: column;

    @include breakpoint(sm) {
        flex-flow: row;
    }
    

   

}

.title{





text-transform:uppercase;
font-family: "EuclidSquare-SemiboldS";
letter-spacing: 2px;
font-size:1.6rem;
line-height: 2.6rem;
margin-bottom:2rem;
@include breakpoint(sm) {
    flex-basis: 30%;
  
}

.underline{
    background-position: 0 2.3rem;
    background-image: linear-gradient(to right, black 0, black 100%);
    background-size: 100% 2px;
    background-repeat: no-repeat;
    display: inline-block;
}


}

.about_line{

    height: 1px;
    widows: 100%;
    margin-top:4rem;
 
    background-color: black;
    
    @include breakpoint(sm) {
        margin-top:10rem;
      
    }


    
}


.column{
    font-family: 'EuclidSquare-MediumS';
    font-size: 1.75rem;
    line-height:2.5rem;
   
    flex-basis: 35%;
  

    p{
        margin:0;
    }
    @include breakpoint(sm) {
        margin-top:0.6rem;
      
    }
    

}