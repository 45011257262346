
@import "../../sass/breakpoints.scss";
.about{
  
 
    
    
    margin:16rem $margin-default 0 $margin-default;
  
    @include breakpoint(sm) {
        margin:16rem $margin-small 0 $margin-small;
    }

    @include breakpoint(md) {
        margin:16rem $margin-medium 0 $margin-medium;
    }
   .content{
    margin:4rem 0 4rem 0;
  
   

    

    
   }
 

    


   .intro{

    font-family: 'EuclidSquare-SemiboldS';
    font-size: 6rem; 
    line-height:8rem;
    color:black;
    

    

    .underline{
        background-position: 0.4rem 8rem;
        background-image: linear-gradient(to right, black 0, black 100%);
        background-size: 100% 10px;
        background-repeat: no-repeat;
        display: inline-block;
    
      
    }
     
    
   

   

      
   
   }

 
}