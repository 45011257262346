.loader_parent{


        display: flex;
        justify-content:center;
        align-items:center;
        height:600px;



}

.loader{
    width:10px;
    height:10px;
    background-color: black;
    border-radius:10px;
    animation-name:blink;
    animation-iteration-count: infinite;
    animation-duration: 2s;
 
   
}

@keyframes blink{
    0%   {
        
        background-color: black;
        width:10px;
        height:10px;
    
    }
    33%  {background-color: black;
        width:20px;
        height:20px;
    }
    100%  {background-color: black;}

}