@import "../../sass/breakpoints.scss";


.imageContainer{
 

    display:flex;
    flex-direction:row;
    margin:2.5rem 0 2.5rem 0;
    background-size: 100%;
    align-items: flex-start;
    
    position: relative;
    overflow: hidden;
 
    transition:margin 500ms;

    margin-top:-15rem;
    @include breakpoint(sm) {
        margin-top:-20rem;
    }

    @include breakpoint(md) {
        margin-top:-30rem;
    }

    


      

         
   

}

.column{

    margin:3rem 0 3rem 0;
    margin-right:0.8rem;
    
  
    @include breakpoint(sm) {
        margin:2.5rem 0 5rem 0;
        margin-right:1.2rem;
  
        
    }
    @include breakpoint(md) {
     
        margin-right:2rem;
    }





&:first-child{
 
    margin-left:0rem;
    
    .image{
        margin-left:-0.7rem;
        @include breakpoint(sm) {
            margin-left:-1.2rem;
        }
        @include breakpoint(md) {
            margin-left:-2rem;
        }
    }

}


&:last-child{
 

    margin-right:0rem;
   
    @include breakpoint(sm) {
     
    }
    .image{
   
        margin-right:-0.7rem;
        @include breakpoint(sm) {
            margin-right:-1.2rem;
        }
        @include breakpoint(md) {
            margin-right:-2rem;
        }
    }

}

}

.image{
    overflow: hidden;
    border-radius: .6rem;
    margin:0.7rem 0 0.7rem 0;
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, .15);
    transition: box-shadow 500ms;
    @include breakpoint(sm) {
        margin:1.2rem 0 1.2rem 0;
     
        border-radius: .7rem;
    }
    @include breakpoint(md) {
        margin:2rem 0 2rem 0;
        box-shadow: 0 20px 50px 0px rgba(0, 0, 0, .2);
        border-radius: 1.4rem;
       
    }
 
    
 
    img {
        width: 100%; 
    

        
    }
   

    
    
}