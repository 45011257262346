@import "../../sass/breakpoints.scss";
.video{
   
video{
    width: 100%;
    height:auto;

    
}





}


.margins_none{
  margin:0;
}



.margins_both{
  margin:0.3rem 0 0.3rem 0;
  @include breakpoint(sm) {
    margin:0 2.5rem 0 2.5rem;
  }

}