@import "../../sass/breakpoints.scss";

.paragraph_content{

    
    display: flex;
    border:1px solid red;
    
    justify-content: center;
    margin:0rem 2rem 2rem 2rem;
    
    @include breakpoint(sm) {
 
        margin:0 0 6rem 0;
    
      }

      @include breakpoint(md) {
        margin:-2rem 0rem 8rem 0;
      }
   

}



.paragraph{
    border:1px solid red;
    font-family: 'EuclidSquare-MediumS';
  

    
    font-size: 1.2rem;
   
    max-width: 700px;
    padding: 0;
    color: #999999;

    @include breakpoint(sm) {
          
            font-size: 1.4rem;
            line-height:3.4rem;
        }

        @include breakpoint(md) {
          
        }
}