@import "../../sass/breakpoints.scss";
.image_container{
    
 
    display:flex;
  

    @include breakpoint(sm) {
    
      
      }
    
}


.margins_none{
    margin:0 1.5rem 0 1.5rem;

    @include breakpoint(sm) {
    
        margin:0 2.5rem 0 2.5rem;
      }
}

.margins_both{
    margin:1.5rem 1.5rem 1.5rem 1.5rem;

    @include breakpoint(sm) {
    
        margin:4rem 2.5rem 4rem 2.5rem;
      }
}

.margins_top{

    margin:1.5rem 1.5rem 0 1.5rem;

    @include breakpoint(sm) {
    
        margin:4rem 2.5rem 0 2.5rem;
      }
    
}


.margins_bottom{
    margin:0 1.5rem 1.5rem 1.5rem;

    @include breakpoint(sm) {
    
        margin:0 2.5rem 4rem 2.5rem;
      }
}



.column_1_3{



    flex-basis: 50%;
    display:flex;
 
    align-content:flex-start;
    flex-wrap: wrap;

    @include breakpoint(lg) {
        flex-basis: 33.3333%;
      }
    

}

.column_2_3{
    flex-basis: 50%;
    align-content:flex-start;




    display:flex;
  
    flex-wrap: wrap;
    @include breakpoint(lg) {
        flex-basis: 66.6666%;
      }
 
}


.column_1{



    flex-basis: 50%;
    display:flex;
 
    align-content:flex-start;
    flex-wrap: wrap;

    @include breakpoint(lg) {
        flex-basis: 33.3333%;
      }
    

}

.column_2{
    flex-basis: 50%;
    align-content:flex-start;




    display:flex;
  
    flex-wrap: wrap;
    @include breakpoint(lg) {
        flex-basis: 66.6666%;
      }
 
}

.image_half{
    flex-basis: 100%;
 
    @include breakpoint(lg) {
        flex-basis: 50%;
      }

       
   img {
    width: 100%; 
    height: auto;

}
    
}
.image{


    flex-basis: 100%;
    width:100%;
    height: auto;
    

    
  

   
    
    
   img {
        width: 100%; 
        height: auto;

    }
      
      
    &:first-child{
        
    }

    &:last-child{
 
    }
}