
@import "../../sass/breakpoints.scss";
.image{

    img{
        width: 100%;
        height: auto;
    }
   
}

.margin{
    margin:.3rem 0 .3rem 0;
    @include breakpoint(sm) {
        margin:3rem 2.5rem 3rem 2.5rem;
      }
}
.no_margin{
    margin:0rem;
}

