@import "../../sass/breakpoints.scss";
.footer{

    border-top:7px solid black;
    margin:2.5rem 1.6rem 5rem 1.6rem;
    padding-top:2rem;
    font-family: 'EuclidSquare-MediumS';

    @include breakpoint(sm) {
        margin:2.5rem 2rem 5rem 2rem;
    }

    @include breakpoint(md) {
        margin:2.5rem 2.5rem 5rem 2.5rem;
    }


    p{
        margin:0;
        a{
            color:black;
            font-size:1.3rem;
            text-decoration: none;
            background-image: linear-gradient(to right, black 0, black 100%);
            background-position: 0 1.5rem;
            background-repeat: no-repeat;
            background-size: 0 1px;
            transition: background-size 500ms;
            &:hover{
                background-image: linear-gradient(to right, black 0, black 100%);
                background-size: 100% 1px;
            }

        }

       
    }
}