
@import "../../sass/breakpoints.scss";







.project_nav{
    border-top:1px solid black;

  
 
    margin:4rem $margin-default 2rem $margin-default;
   
   
   
    @include breakpoint(sm) {
        margin:4rem $margin-small 2rem $margin-small;
               
             
               
    }

    @include breakpoint(sm) {
        margin:4rem $margin-medium 2rem $margin-medium;
               
             
               
    }

    .case_studies{

    
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @include breakpoint(sm) {
            flex-direction: row;
       
        }
      
    }

    h2{

        font-family: 'EuclidSquare-SemiboldS';
        font-size: 3rem; 
        line-height:6rem;
        color:black;
        margin:2rem 0 4rem 0; 
        
        
        
    
        
    
        
         
        
       
    
       
    
          
       
       }
 

       .underline{
        background-position: 0 5rem;
        background-image: linear-gradient(to right, black 0, black 100%);
        background-size: 100% 5px;
        background-repeat: no-repeat;
        display: inline-block;
    
      
    }

}

