@import "../../sass/breakpoints.scss";

.paragraph_content{

    
    padding-top:2rem;

   

}



.paragraph{
    font-family: "SangBlueKingdom-MediumS";
  
    max-width: 1000px;
    font-size: 2rem;    
    line-height: 2.6rem;
    @include breakpoint(sm) {
        font-size: 2.8rem;    
        line-height: 3.6rem;
    }

    @include breakpoint(md) {
        font-size: 3.5rem;    
        line-height: 4.4rem;

       
    }
}