@import "../../sass/breakpoints.scss";
.video{
    background-color: #666666;
    
    padding:4rem;
    @include breakpoint(sm) {
      padding:10rem;

    }

    @include breakpoint(md) {
      padding:15rem;
    }

.shadow{

 

  

}  
video{
    width: 100%;

    box-shadow: 0 30px 50px 0px rgba(0, 0, 0, .2);
 
  
   
    
    

    
}

.browser_bar{
  height:2rem;
  border-radius: .5rem .5rem 0 0;
  display: flex;
  align-items: center;
  padding-left: .3rem;
  @include breakpoint(sm) {
    height:2.3rem;
    border-radius: .6rem .6rem 0 0;
  }

  @include breakpoint(md) {
    height:3rem;
    padding-left: .5rem;
    border-radius: .8rem .8rem 0 0;
  }

  background-color: #999999;

  .button{
    background-color: #fff;
    width:.8rem;
    height:.8rem;
    border-radius: .8rem;
    margin-left: .6rem;


    @include breakpoint(md) {
    width:1.1rem;
    height:1.1rem;
    border-radius: 1.1rem;
    margin-left: .8rem;

    }
    
  }
  

}

}