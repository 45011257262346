@import "../../sass/breakpoints.scss";

.tile{

    width:100%;


    &:nth-child(2){
        @include breakpoint(md) {
        margin-left: 0rem;
        }
    }
   
    a{
        text-decoration: none;


        &:hover{
         
            text-decoration: none;
        }
    }

    video{
        width: 100%;
        height:auto;
    }

    img{
        width: 100%;
        height:auto;
    }

}

