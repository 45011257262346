@import "../../sass/breakpoints.scss";

.tile{
   
    flex-basis: 100%;
    
    @include breakpoint(sm) {
       
        flex-basis: 50%;
       
      

    
        
    }

    @include breakpoint(md) {
       
        flex-basis: 25%;
       
      

    
        
    }



   a{
       text-decoration: none;
       color:black;
       &:hover{
        text-decoration: none;
        color:black;
       }
   }

   

   
  

}


.thumbnail{
    position: relative;
  
    
    .image{
   
        overflow: hidden;


    }

    .video{
   
        overflow: hidden;
        width:100%;
        height:0px;
        padding-bottom:56.25%;
   
       
    }

    video{
        width: 100%;
        height:auto;
        transition: transform 500ms;
        
    }

    img{
        width: 100%;
        height:auto;
        transition: transform 500ms;


        
    }
    

    
      
        .project_name{
            text-transform:uppercase;
            font-family: "EuclidSquare-SemiboldS";
            
            letter-spacing: 1px;
            font-size:1.6rem;
            line-height: 1.8rem;
            width: 80%;
            margin:1rem 0 3rem 0;
            
           
            @include breakpoint(sm) {
           
               
             
               
            }

            @include breakpoint(lg) {
          
   
            }

            .underline{
                background-image: linear-gradient(to right, black 0, black 100%);
                background-position: 0 1.8rem;
                background-repeat: no-repeat;
                background-size: 0% 1px;
                transition: 500ms;
               
                
                
               
            }

        }

       

       
    
    
   
        
      
    



.project_info{
   
    

  
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: .5s;
        display: flex;
    
    
   

   
}    






}