@import "../../sass/breakpoints.scss";

.row{
  

  

  .content{
    display: flex;
    justify-content: center;
    flex-direction:column;

    margin:0 ;
    @include breakpoint(sm) {
      margin:0 $margin-small 0 $margin-small;
    }
      @include breakpoint(md) {
        flex-direction:row;
        margin:0 $margin-medium 0 $margin-medium;
        
      }
     
    }
    

    .triple{
       
        display: flex;
     
        flex-direction:column;
        @include breakpoint(md) {
          
          flex-direction:row;
        }

        .triple_first{
          margin-right:0;
          flex-basis: 70%;


        }

        .triple_other{
          flex-basis: 35%;
          flex-direction:row;

          @include breakpoint(md) {
            flex-basis: 35%;
            flex-direction:column;
        }
          
          
      
          
        }

        
    }
  
  
  
  }



