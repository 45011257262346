@import "../../sass/breakpoints.scss";

.home_grid{

   
    margin-top:6rem;
    @include breakpoint(sm) {
        
        margin-top:8rem;
    }
}